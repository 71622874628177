import '../App.css';
import React from 'react';

const BaseMapSetting = ({ basemap, selectedBasemap, setSelectedBasemap }) => {

    return (
        <>
            <div className="basemapcategorytitle">地理院地図</div>
            <div className="basemapcategory">
                <label htmlFor="gsistd" className="basemap">
                    <input type="radio" value={"0"} id="gsistd" name="basemap" onChange={(e) => setSelectedBasemap(e.target.value)} checked={selectedBasemap === "0"}></input>
                    <span className="basemap-text">標準地図</span>
                </label>
                <label htmlFor="gsipale" className="basemap">
                    <input type="radio" value={"1"} id="gsipale" name="basemap" onChange={(e) => setSelectedBasemap(e.target.value)} checked={selectedBasemap === "1"}></input>
                    <span className="basemap-text">淡色地図</span>
                </label>
                <label htmlFor="gsiblank" className="basemap">
                    <input type="radio" value={"2"} id="gsiblank" name="basemap" onChange={(e) => setSelectedBasemap(e.target.value)} checked={selectedBasemap === "2"}></input>
                    <span className="basemap-text">白地図</span>
                </label>
                <label htmlFor="gsiphoto" className="basemap">
                    <input type="radio" value={"3"} id="gsiphoto" name="basemap" onChange={(e) => setSelectedBasemap(e.target.value)} checked={selectedBasemap === "3"}></input>
                    <span className="basemap-text">写真</span>
                </label>
            </div>
            <div className="basemapcategorytitle">OpenStreetMap</div>
            <div className="basemapcategory">
                <label htmlFor="osm" className="basemap">
                    <input type="radio" value={"4"} id="osm" name="basemap" onChange={(e) => setSelectedBasemap(e.target.value)} checked={selectedBasemap === "4"}></input>
                    <span className="basemap-text">標準</span>
                </label>
            </div>
            <div className="basemapcategorytitle">平凡社地図出版</div>
            <div className="basemapcategory">
                <label htmlFor="heibon" className="basemap">
                    <input type="radio" value={"5"} id="heibon" name="basemap" onChange={(e) => setSelectedBasemap(e.target.value)} checked={selectedBasemap === "5"}></input>
                    <span className="basemap-text">標準</span>
                </label>
            </div>
        </>
    )
}

export default BaseMapSetting;