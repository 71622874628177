import React from 'react';
import { Accordion, ListGroup, Form, Badge, Card } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';

const LayerSetting = ({ mode, layer, selectedLayer, setSelectedLayer, category }) => {

    const layer_change = (e) => {
        if (selectedLayer.some(layer => layer.id === e.target.value)) {
            setSelectedLayer(
                selectedLayer.filter(layer => layer.id !== e.target.value)
            );
        } else {
            setSelectedLayer([...selectedLayer, layer.filter(layer => layer.id === e.target.value)].flat());
        }
    };

    const categorizedLayers = category.map((category) => {
        // category.typeが"layer"でない場合はnullを返してスキップ
        if (category.type !== "layer") return null;

        const categoryLayers = layer.filter((layer) => layer.category === category.id);
        return { ...category, layers: categoryLayers };
    }).filter(Boolean); // nullの値を除去

    return (
        <>
            <Accordion flush>
                {categorizedLayers.map((category, index) => (
                    <Accordion.Item eventKey={index.toString()} key={category.id}>
                        <Accordion.Header as={'p'}>{category.name}</Accordion.Header>

                        {category.layers.length > 0 ? (
                            <Accordion.Body>
                                <ListGroup>
                                    {category.layers.map((layer) => (
                                        <ListGroup.Item key={layer.id}>
                                            <Form.Check type="checkbox" inline key={layer.id} id={layer.id} label={layer.name} value={layer.id} onChange={layer_change} checked={selectedLayer.some(slayer => slayer.id === layer.id)} />
                                        </ ListGroup.Item>
                                    ))}
                                </ListGroup>
                            </Accordion.Body>
                        ) : (
                            <Accordion.Body>レイヤがありません</Accordion.Body>
                        )}

                    </Accordion.Item>
                ))}
            </Accordion>

            <Card>
                <Card.Header>選択中のレイヤ</Card.Header>
                {selectedLayer.length > 0 ? (
                    <ListGroup variant="flush">
                        {selectedLayer.map((layer) => (
                            <ListGroup.Item key={layer.id}>
                                <Form.Check type="checkbox" inline key={layer.id} id={layer.id + "_2"} label={layer.name} value={layer.id} onChange={layer_change} checked={selectedLayer.some(slayer => slayer.id === layer.id)} /><Badge bg="primary">{layer.note}</Badge>
                            </ ListGroup.Item>
                        ))}
                    </ListGroup>
                ) : (
                    <Card.Body>レイヤがありません</Card.Body>
                )}
            </Card>
        </>
    )
}

export default LayerSetting;