import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Nav, Navbar } from 'react-bootstrap';
import '../App.css';
import { Link } from 'react-router-dom';

const Header = () => {
    return (
        <Navbar className="header_style" variant="dark" fixed="top" expand="sm">
            <Navbar.Brand className="header_title">教育用WebGIS</Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto">
                    <Nav.Link href="/">初期表示</Nav.Link>
                    <Nav.Link as={Link} to="/change_log" >更新履歴</Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};

export default Header;