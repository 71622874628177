import './App.css';
import React, { useState, useEffect } from 'react'
import Header from './components/header'
import MapSetting from './components/mapsetting';
import MapView from './map/mapview';
import basemap from './list/basemap.json';
import layer from './list/layer.json';
import category from './list/category.json';
import world from './data/world.json';
import japan from './data/japan.json';

function App() {

  const [selectedBasemap, setSelectedBasemap] = useState("0");
  const [selectedLayer, setSelectedLayer] = useState([]);
  const [mode, setMode] = useState("world")
  const [geojsonData, setGeojsonData] = useState(world);

  useEffect(() => {
    if (mode === "world") {
      setGeojsonData(world)
    }
    else if (mode === "japan") {
      setGeojsonData(japan)
    }
  }, [mode])

  return (
    <>
      <Header />
      <MapSetting
        mode={mode}
        basemap={basemap}
        selectedBasemap={selectedBasemap}
        setSelectedBasemap={setSelectedBasemap}
        layer={layer}
        selectedLayer={selectedLayer}
        setSelectedLayer={setSelectedLayer}
        geojsonData={geojsonData}
        category={category}
      />
      <MapView
        mode={mode}
        basemap={basemap}
        selectedBasemap={selectedBasemap}
        setSelectedBasemap={setSelectedBasemap}
        layer={layer}
        selectedLayer={selectedLayer}
        setSelectedLayer={setSelectedLayer}
        geojsonData={geojsonData}
      />
    </>
  );
}

export default App;