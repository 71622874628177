import '../App.css';
import 'leaflet/dist/leaflet.css';
import React from 'react'
import { MapContainer, ScaleControl } from 'react-leaflet'
import Leaflet from "leaflet";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import BaseMap from "./basemap";
import Layer from "./layer";

const MapView = ({mode, basemap, selectedBasemap, setSelectedBasemap, layer, selectedLayer, setSelectedLayer, geojsonData}) => {

    //markerの設定
    let DefaultIcon = Leaflet.icon({
        iconUrl: icon,
        shadowUrl: iconShadow,
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        tooltipAnchor: [16, -28],
        shadowSize: [41, 41]
    });
    Leaflet.Marker.prototype.options.icon = DefaultIcon;

    //緯度経度
    const position = [20, 150];

    //初期ズームレベル
    const zoom = 3;

    return (
        <MapContainer center={position} zoom={zoom} preferCanvas={true} worldCopyJump={true} boxZoom={true} doubleClickZoom={true} scrollWheelZoom={true} touchZoom={true}>
            <BaseMap
                basemap={basemap}
                selectedBasemap={selectedBasemap}
                setSelectedBasemap={setSelectedBasemap}
            />
            <Layer
                layer={layer}
                selectedLayer={selectedLayer}
                setSelectedLayer={setSelectedLayer}
                geojsonData={geojsonData}
            />
            <ScaleControl metric={true} imperial={false} position="bottomright" />
        </ MapContainer>
    )
}

export default MapView;