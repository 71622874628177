import React from 'react';
import Header from "./components/header";
import { Table } from 'react-bootstrap';

function log() {
  return (
    <>
      <Header />
      <Table striped responsive>
        <thead className="table-dark text-center">
          <tr>
            <th>年月日</th>
            <th>変更箇所</th>
            <th>変更内容</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">2025/3/00</th>
            <td>公開予定</td>
            <td>公開予定</td>
          </tr>
        </tbody>
      </Table>
    </>
  );
}

export default log;