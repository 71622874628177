import '../App.css';
import 'leaflet/dist/leaflet.css';
import React from 'react'
import { Badge } from 'react-bootstrap';
import { TileLayer, Marker, CircleMarker, Popup, Tooltip, FeatureGroup } from 'react-leaflet'
import Leaflet from "leaflet";
import card_list from '../list/card_list.json';
import parse from 'html-react-parser';

const Layer = ({ mode, layer, selectedLayer, setSelectedLayer, geojsonData }) => {

  const rasters = layer
    .filter(layer => selectedLayer.some(slayer => slayer.id == layer.id && slayer.type == "raster")) // 一致する要素のみ抽出
    .map(layer => (
      <TileLayer attribution={layer.attribution} url={layer.url} key={layer.id} /> // 一致する要素を使って処理を実行
    ));

  let climates = null; // climates を初期化
  let manhole_distributions = null; // manhole_distributions を初期化

  // climates の処理
  if (geojsonData && selectedLayer.some(layer => layer.type === "world" || layer.type === "japan")) {
    climates = geojsonData.map((feature, index) => {
      if (feature.geometry.coordinates[0] >= -25) {
        return (
          <FeatureGroup key={"c" + index} attribution="理科年表">
            <Popup>
              <p>{feature.properties.city}</p>
            </Popup>
            <CircleMarker
              center={[
                feature.geometry.coordinates[1],
                feature.geometry.coordinates[0]
              ]}
              fillColor="#ff7800"
              radius={5}
              color={"#000"}
              weight={0.5}
              opacity={1}
              fillOpacity={0.8}
            />
          </FeatureGroup>
        );
      } else {
        return (
          <FeatureGroup color="purple" key={"c" + index} attribution="理科年表">
            <Popup>
              <p>{feature.properties.city}</p>
            </Popup>
            <CircleMarker
              center={[
                feature.geometry.coordinates[1],
                feature.geometry.coordinates[0] + 360
              ]}
              fillColor="#ff7800"
              radius={5}
              color={"#000"}
              weight={1}
              opacity={1}
              fillOpacity={0.8}
            />
          </FeatureGroup>
        );
      }
    });
  }

  // manhole_distributions の処理
  if (card_list && selectedLayer.some(layer => layer.type === "manhole_distribution")) {
    manhole_distributions = card_list.map((card, cardIndex) => {
      if (card.locations.length) {
        // 各 card に対して、複数の locations を処理
        const markers = card.locations.map((location, locIndex) => (
          <CircleMarker
            key={`m${cardIndex}-loc${locIndex}`} // ユニークなキー
            center={[
              Number(location.latitude),
              Number(location.longitude)
            ]}
            fillColor="#694513"
            radius={5}
            color={"#000"}
            weight={1}
            opacity={1}
            fillOpacity={0.8}
          >
            <Popup>
              <p style={{"font-weight": "bold"}}>{card.card_name}<Badge bg="secondary">{"第" + card.version + "弾"}</Badge></p>
              <p>{location.name}</p>
              <p>{parse(card.open_time)}</p>
              <p>{parse(card.stock)}</p>
              <img src={"/manhole/" + card.card_image} className="manhole_card" />
            </Popup>
          </CircleMarker>
        ));

        return (
          <FeatureGroup color="purple" key={`fg${cardIndex}`} attribution="<a href='https://www.gk-p.jp/activity/mc/' target='_blank'>下水道広報プラットフォーム</a>">
            {markers}
          </FeatureGroup>
        );
      }
      return null; // 条件を満たさない場合は `null` を返す
    }).filter(item => item !== null); // 無効な要素を除外
  }

  return (
    <>
      {rasters}
      {climates}
      {manhole_distributions}
    </>
  )
};

export default Layer;